/* Settings Container */
.settings-container {
    background: #ffffff;
    padding: 1.5rem;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 1.5rem;
  }
  
  /* Individual Settings Item */
  .settings-item {
    margin-bottom: 2rem;
  }
  
  .settings-item h2 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #2c3e50;
  }
  
  .settings-item p {
    font-size: 16px;
    color: #666666;
    margin: 0.5rem 0 1rem;
  }
  
  /* Settings Button */
  .settings-button {
    padding: 10px 20px;
    background-color: #0056b3;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .settings-button:hover {
    background-color: #003f8a;
  }
  
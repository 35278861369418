/* General Container Styles */
.dashboard-content {
    padding: 20px;
    background-color: #f9f9f9; /* Light gray background */
}

.dashboard-title {
    font-family: 'Arial', sans-serif;
    font-size: 28px;
    font-weight: bold;
    color: #2c3e50; /* Dark blue-gray */
    text-align: center;
    margin-bottom: 30px;
}

/* Billing Container */
.billing-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Responsive grid */
    gap: 20px; /* Spacing between cards */
}

/* Project Card */
.billing-info-container {
    background-color: #ffffff; /* White background */
    border: 1px solid #e0e0e0; /* Light gray border */
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 0.3s ease; /* Smooth hover effect */
}

.billing-info-container:hover {
    transform: translateY(-5px); /* Lift on hover */
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15); /* More pronounced shadow */
}

/* Action Buttons */
.fund-project-button,
.see-all-milestones-button,
.release-payment-button {
    font-family: 'Arial', sans-serif;
    font-size: 14px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 10px auto;
}

/* Fund Project Button */
.fund-project-button {
    background-color: #4caf50; /* Green background */
    color: white;
}

.fund-project-button:hover {
    background-color: #45a049; /* Slightly darker green */
    transform: scale(1.05); /* Slight zoom effect */
}

/* See All Milestones Button */
.see-all-milestones-button {
    background-color: #007bff; /* Blue background */
    color: white;
}

.see-all-milestones-button:hover {
    background-color: #0056b3; /* Slightly darker blue */
    transform: scale(1.05); /* Slight zoom effect */
}

/* Disabled Button */
button:disabled {
    background-color: #cccccc; /* Gray background for disabled state */
    color: #666666; /* Darker gray text */
    cursor: not-allowed;
}

/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

/* Modal Container */
.modal-container {
    background-color: #ffffff; /* White background */
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
    padding: 20px;
    width: 90%; /* Take up to 90% of the viewport width */
    max-width: 600px; /* Maximum width */
    max-height: 80vh; /* Prevent exceeding 80% of the viewport height */
    overflow-y: auto; /* Add vertical scrollbar if content exceeds height */
    position: relative; /* For the close button */
}

/* Modal Close Button */
.modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 24px;
    font-weight: bold;
    color: #666666;
    cursor: pointer;
    transition: color 0.3s ease;
}

.modal-close-button:hover {
    color: #000000;
}

/* Modal Title */
.modal-container h2 {
    font-family: 'Arial', sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: #2c3e50; /* Dark blue-gray */
    margin-bottom: 20px;
    text-align: center;
}

/* Milestone List in Modal */
.milestone-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.milestone-item {
    background-color: #f9f9f9; /* Light gray */
    border: 1px solid #e0e0e0; /* Light gray border */
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.milestone-item h4 {
    font-family: 'Arial', sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #003780; /* Dark blue */
    margin-bottom: 5px;
}

.milestone-item p {
    font-family: 'Arial', sans-serif;
    font-size: 14px;
    color: #666666; /* Medium gray */
    margin: 5px 0;
}

/* Release Payment Button */
.release-payment-button {
    background-color: #007bff; /* Blue background */
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 10px;
    transition: all 0.3s ease;
}

.release-payment-button:hover {
    background-color: #0056b3; /* Slightly darker blue */
    transform: scale(1.05); /* Slight zoom effect */
}

.projects-container {
    padding: 1rem;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 1rem;
  }
  
  .project-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .project-item {
    padding: 1rem;
    margin-bottom: 1.5rem;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .project-item h3 {
    font-size: 20px;
    margin-bottom: 0.5rem;
    color: #34495e;
  }
  
  .project-item p {
    margin: 0.5rem 0;
    color: #666666;
  }
  
  .milestones {
    margin-top: 1rem;
  }
  
  .milestone-list {
    list-style-type: none;
    padding: 0;
  }
  
  .milestone-item {
    padding: 0.5rem;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .milestone-item:last-child {
    border-bottom: none;
  }
  
  .milestone-item p {
    margin: 0.2rem 0;
    color: #444444;
  }
  
/* Base Styles */
html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
  }
  
  .landing-page {
    display: flex;
    flex-direction: row;
    height: 100vh;
  }
  
  /* Header Section */
  .landing-header {
    background-color: #f4f4f4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    max-width: 400px;
    height: 100%;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .landing-logo {
    max-width: 150px;
    height: auto;
    margin-bottom: 1rem;
  }
  
  .landing-text h1 {
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    margin: 0;
  }
  
  .landing-text h2 {
    font-size: 18px;
    color: #666666;
    margin: 0.5rem 0 0;
  }
  
  /* Main Content Section */
  .landing-main {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../../assets/heroimage.svg'); /* Background image for the right side */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    overflow: hidden;
  }
  
  /* Login/Sign Up Form Container */
  .login-container {
    width: 100%;
    max-width: 500px;
    background-color: rgba(255, 255, 255, 0.95); /* Slight transparency */
    border-radius: 12px;
    padding: 2rem;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.1);
    max-height: 80vh; /* Restrict height to fit within the viewport */
    overflow-y: auto; /* Add vertical scroll if content overflows */
    margin: 2rem 0; /* Top and bottom padding for spacing */
  }
  
  /* Scrollbar Styling */
  .login-container::-webkit-scrollbar {
    width: 6px;
  }
  
  .login-container::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 6px;
  }
  
  .login-container::-webkit-scrollbar-thumb:hover {
    background: #999;
  }
  
  .login-container::-webkit-scrollbar-track {
    background: #f4f4f4;
  }
  
  /* Form Styling */
  .login-form .form-group {
    margin-bottom: 1.5rem;
  }
  
  .login-form label {
    display: block;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #444444;
  }
  
  .login-form input,
  .login-form select {
    width: 100%;
    padding: 0.85rem;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    font-size: 14px;
    background-color: #f9f9f9;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.1);
    margin: 0;
    outline: none;
    transition: box-shadow 0.3s ease, border-color 0.3s ease;
  }
  
  .login-form input:focus,
  .login-form select:focus {
    border-color: #0056b3;
    box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.15), 0px 0px 4px rgba(0, 86, 179, 0.5);
  }
  
  .login-button {
    width: 100%;
    padding: 0.85rem;
    background-color: #0056b3;
    color: #ffffff;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 1rem;
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .login-button:hover {
    background-color: #003f8a;
    transform: translateY(-2px);
  }
  
  .login-button:active {
    transform: translateY(0);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .landing-page {
      flex-direction: column;
    }
  
    .landing-header {
      width: 100%;
      height: auto;
      padding: 1rem;
      max-width: none;
    }
  
    .landing-main {
      width: 100%;
      padding: 1rem;
    }
  
    .login-container {
      width: 100%;
      max-width: 400px;
      margin: 2rem auto;
      max-height: 80vh; /* Maintain scrollable form for smaller screens */
    }
  }
  
  
  /* Form Inputs */
  .login-form .form-group {
    margin-bottom: 1.5rem;
  }
  
  .login-form label {
    display: block;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #444444;
  }
  
  .login-form input {
    width: 100%;
    padding: 0.85rem; /* Same padding on all sides for balance */
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    font-size: 14px;
    background-color: #f9f9f9;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.1); /* Inner shadow for depth */
    margin: 0; /* Remove any default margins */
    outline: none;
    transition: box-shadow 0.3s ease, border-color 0.3s ease;
  }
  
  .login-form input:focus {
    border-color: #0056b3;
    box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.15), 0px 0px 4px rgba(0, 86, 179, 0.5);
  }
  
  /* Ensuring Consistent Padding for Container */
  .login-container input {
    box-sizing: border-box; /* Ensures padding is included within the width */
  }
  
  
  /* Form Inputs */
  .login-form .form-group {
    margin-bottom: 1.5rem;
  }
  
  .login-form label {
    display: block;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #444444;
  }
  
  .login-form input {
    width: 100%;
    padding: 0.85rem; /* Slightly increased padding for comfort */
    border: 1px solid rgba(0, 0, 0, 0.15); /* Subtle border */
    border-radius: 6px;
    font-size: 14px;
    background-color: #f9f9f9;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.1); /* Inner shadow for depth */
    margin: 0; /* Remove any default margins */
    outline: none; /* No outline when focused */
    transition: box-shadow 0.3s ease, border-color 0.3s ease;
  }
  
  .login-form input:focus {
    border-color: #0056b3; /* Highlighted border on focus */
    box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.15), 0px 0px 4px rgba(0, 86, 179, 0.5); /* Enhanced focus shadow */
  }
  
  /* Login Button */
  .login-button {
    width: 100%;
    padding: 0.85rem;
    background-color: #0056b3; /* Matches aigencyone.com */
    color: #ffffff;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 1rem;
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .login-button:hover {
    background-color: #003f8a; /* Slightly darker shade */
    transform: translateY(-2px); /* Slight hover effect */
  }
  
  .login-button:active {
    transform: translateY(0); /* Reset on click */
  }
  
  /* Footer Links */
  .login-footer {
    margin-top: 1rem;
    text-align: center;
  }
  
  .forgot-password-link,
  .sign-up-link {
    color: #0056b3;
    text-decoration: none;
    font-size: 14px;
  }
  
  .forgot-password-link:hover,
  .sign-up-link:hover {
    text-decoration: underline;
  }
  
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .landing-page {
      flex-direction: column;
    }
  
    .landing-header {
      width: 100%; /* Full width for mobile */
      max-width: none; /* Remove max-width for stacking */
      height: auto; /* Allow auto height on mobile */
      padding: 1rem;
    }
  
    .landing-main {
      width: 100%;
      min-height: calc(100vh - 200px); /* Adjust for header height */
      padding: 1rem;
    }
  
    .login-container {
      width: 100%;
      max-width: 400px;
      margin-top: 1rem;
    }
  }
  

  /* Google Login Button */
.google-login {
    margin-top: 1rem;
    text-align: center;
  }
  
  .google-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    padding: 0.75rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    background-color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    color: #444444;
    cursor: pointer;
    transition: box-shadow 0.3s ease, transform 0.2s ease;
  }
  
  .google-button:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
  }
  
  .google-button:active {
    transform: translateY(0);
  }
  
  .google-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  

  /* Success Message */
.success-message {
    color: #28a745; /* Green for success */
    font-size: 14px;
    text-align: center;
    margin-top: 1rem;
  }
  
  /* Error Message */
  .error-message {
    color: #dc3545; /* Red for error */
    font-size: 14px;
    text-align: center;
    margin-top: 1rem;
  }
  

  /* Home Button Styling */
.home-button {
    display: inline-block;
    margin-top: 1rem;
    padding: 0.75rem 1.5rem;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    background-color: #0056b3; /* AigencyOne primary color */
    border: none;
    border-radius: 8px;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.3s ease;
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .home-button:hover {
    background-color: #003f8a; /* Slightly darker shade on hover */
  }
  
  .home-button:active {
    background-color: #002c5d; /* Even darker shade on active */
    box-shadow: inset 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
  
/* Sidebar Container */
.sidebar {
    display: flex;
    flex-direction: column;
    width: 250px;
    height: 100vh;
    background-color: #f4f4f4;
    border-right: 1px solid #e0e0e0;
  }
  
  /* Logo Section */
  .sidebar-logo {
    text-align: center;
    padding: 20px;
  }
  
  .sidebar-logo-img {
    max-width: 100%;
    height: auto;
  }
  
  /* Navigation Section */
  .sidebar-nav {
    flex-grow: 1;
    padding: 20px;
  }
  
  .sidebar-nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .sidebar-link {
    display: block;
    padding: 10px 15px;
    color: #333333;
    text-decoration: none;
    font-size: 16px;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .sidebar-link:hover {
    background-color: #0056b3;
    color: #ffffff;
  }
  
  /* Logout Button */
  .sidebar-logout {
    margin-top: auto;
    padding: 20px;
    text-align: center;
  }
  
  .logout-button {
    width: 90%;
    padding: 10px;
    background-color: #d9534f;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .logout-button:hover {
    background-color: #c9302c;
  }
  
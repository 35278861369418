/* Main container that holds the sidebar and dashboard content */
.dashboard-container {
    display: flex;
    height: 100vh;
    width: 100%;
  }
  
  /* Sidebar section */
  .sidebar {
    flex: 0 0 250px; /* Fixed width for sidebar */
  }
  
  /* Dashboard content section */
  .dashboard-content {
    flex: 1; /* Fills the remaining space */
    padding: 2rem;
    background-color: #f9f9f9;
    overflow-y: auto; /* Add scrolling if content overflows */
  }
  
  /* Welcome message styling */
  .dashboard-content h1 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 1.5rem;
    color: #2c3e50;
  }
  
  /* Section styling */
  .dashboard-section {
    margin-bottom: 2rem;
    padding: 1.5rem;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .dashboard-section h2 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #34495e;
  }
  
  .dashboard-section p {
    font-size: 16px;
    color: #666666;
    margin: 0;
  }
  
  /* Projects section styling */
  .projects-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
  }
  
  .project-item {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 1.5rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .project-item:hover {
    transform: translateY(-5px);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .project-item h3 {
    font-size: 18px;
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 0.5rem;
  }
  
  .project-item p {
    font-size: 14px;
    color: #666666;
    margin: 0.25rem 0;
  }
  
  .project-item .project-status {
    font-size: 14px;
    font-weight: bold;
    color: #27ae60; /* Green for completed status */
  }
  
  .project-item .project-status.in-progress {
    color: #f39c12; /* Orange for in-progress status */
  }
  
  .project-item .project-status.pending {
    color: #e74c3c; /* Red for pending status */
  }
  
  .project-item .project-due-date {
    font-size: 12px;
    color: #888888;
    margin-top: 0.5rem;
  }
  
  /* Task list styling */
  .project-tasks {
    margin-top: 1rem;
    padding: 0;
    list-style: none;
  }
  
  .project-task {
    font-size: 14px;
    color: #555555;
    margin-bottom: 0.5rem;
  }
  
  .project-task.completed {
    text-decoration: line-through;
    color: #aaaaaa;
  }
  
  .project-task.in-progress {
    font-weight: bold;
    color: #f39c12;
  }
  
  .project-task.pending {
    color: #e74c3c;
  }
  

  .billing-overview {
    padding: 1rem;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 1rem;
  }
  
  .billing-overview p {
    font-size: 16px;
    color: #34495e;
    margin: 0.5rem 0;
  }
  